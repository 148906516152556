import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Badge, Colors, Icon } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "badge"
    }}>{`Badge`}</h1>
    <p>{`Badges are useful components for displaying small numbers or icons.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Badge} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Badge>1</Badge>\n<br />\n<Badge>10</Badge>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Badge,
      Colors,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge mdxType="Badge">1</Badge>
  <br />
  <Badge mdxType="Badge">10</Badge>
    </Playground>
    <h2 {...{
      "id": "size"
    }}>{`Size`}</h2>
    <Playground __position={2} __code={'<Badge size=\"xs\">101</Badge>\n<br />\n<Badge size=\"sm\">101</Badge>\n<br />\n<Badge size=\"md\">101</Badge>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Badge,
      Colors,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge size="xs" mdxType="Badge">101</Badge>
  <br />
  <Badge size="sm" mdxType="Badge">101</Badge>
  <br />
  <Badge size="md" mdxType="Badge">101</Badge>
    </Playground>
    <h2 {...{
      "id": "with-diffrent-colors"
    }}>{`With diffrent colors`}</h2>
    <Playground __position={3} __code={'<Badge color=\"black\" backgroundColor=\"pink\">\n  101\n</Badge>\n<br />\n<Badge color=\"pink\" backgroundColor=\"black\">\n  101\n</Badge>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Badge,
      Colors,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge color="black" backgroundColor="pink" mdxType="Badge">
    101
  </Badge>
  <br />
  <Badge color="pink" backgroundColor="black" mdxType="Badge">
    101
  </Badge>
    </Playground>
    <h2 {...{
      "id": "pill-badge"
    }}>{`Pill Badge`}</h2>
    <Playground __position={4} __code={'<Badge pill={true}>무료 배송</Badge>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Badge,
      Colors,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge pill={true} mdxType="Badge">무료 배송</Badge>
    </Playground>
    <h2 {...{
      "id": "with-icon"
    }}>{`With Icon`}</h2>
    <Playground __position={5} __code={'<Badge\n  icon={<Icon.ShippingTruck fillColor={Colors.white} />}\n  pill={false}\n  backgroundColor={Colors.green500}\n>\n  무료 배송\n</Badge>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Badge,
      Colors,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge icon={<Icon.ShippingTruck fillColor={Colors.white} />} pill={false} backgroundColor={Colors.green500} mdxType="Badge">
    무료 배송
  </Badge>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      